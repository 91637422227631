export const fearfaqList = [
    {
      id: 1,
      heading: `Let Go Removal of Fear`,
      body: `In helpless circumstances, sincere prayers and surrender to the Divine are very liberating. The ideal time for prayer is bedtime when you are completely free in your body and mind, after the completion of your day’s activities. REMOVAL OF FEAR • Sit in a comfortable position. • Gently close your eyes and bring your attention to your heart. Dive as…...`,
      readmore:
        "https://heartfulness.org/en/faq/let-go-removal-of-fear-2/",
    },
    {
      id: 2,
      heading: `The Antidote to Fear`,
      body: `Courage is the antidote to fear. After removing the root cause of fear, we can then fill the heart with courage and confidence. Deep within your heart, there is a space that is free from fear. Here you will experience total freedom. Taking refuge in this space through regular meditation will help you to feel free of your emotional limitations.…... `,
      readmore:
        "https://heartfulness.org/en/faq/dissolve-anger/",
    },
    {
      id: 3,
      heading: `Remove the Root Cause of Fear`,
      body: `Here are two very effective methods to remove the root cause of fear: Method 1 is for general fear of situations, places, events, etc GUIDED LIMB CLEANING • Imagine the divine current is flowing from above you. • Draw the current down to your heart. • Now let this current move towards your left shoulder, then let it descend down…... `,
      readmore:
        "https://heartfulness.org/en/faq/prevent-anger/",
    },
    {
      id: 4,
      heading: `Why Detox Anger?`,
      body: `If misused, the fire of anger can destroy a happy family, friendship and peace of mind. It can lead to bitterness and revenge, self-affliction, loss of self-confidence and loss of courage.  Anger affects your body, mind, and heart. It is a contraction of energy manifesting as stiffness in the body, physical dysfunction, and a loss of energy, sensitivity, and awareness.…...`,
      readmore: "https://heartfulness.org/en/faq/how-can-i-handle-the-thoughts-that-occur-during-meditation/",
    },
    {
      id: 5,
      heading: `Breathing Exercise`,
      body: `Fear arises first in the energy field of the heart-mind, then in the body, and eventually, it leads to action. The action may be to run away, to attack, to freeze or to call for help so that others come to the rescue, etc. Here is a simple breathing technique that activates the calming part of your autonomic nervous system…...`,
  
      readmore:
        "https://heartfulness.org/en/faq/breathing-exercise/",
    },
    {
      id: 6,
      heading: `Self Study`,
      body: `GUIDED INQUIRY • What situations and conditions create fear in you? • What memories and imaginary situations create fear in you? • What are your worst fears? • Do you know why are you feel fear? (You may not always know the real cause, and that is okay.) • Write your discoveries in your journal...`,  
      readmore:
        "https://heartfulness.org/en/faq/must-we-be-seekers-of-spirituality-in-order-to-practice-heartfulness-is-it-acceptable-to-only-want-peace-and-relaxation/",
    },
   
   
  ];
  