import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Faqbreadcreumb from "../components/faq/faq-breadcumbs";

import Faqsliderpage from "../components/faq/faq-slider";

// markup
const FaqPage = () => {
  return (
    <div>
      <Seo title="FAQ" />

      <Layout isSticky>
        <section>
          <Faqbreadcreumb />
        </section>
        <section>
          <Faqsliderpage />
        </section>
      </Layout>
    </div>
  );
};

export default FaqPage;
