export const instructorfaqList = [
    {
      id: 1,
      heading: `Who are the Heartfulness trainers and what enables them to impart Transmission?`,
      body: `Heartfulness trainers are themselves practitioners on the path who have voluntarily taken up the duty of training others in the practices of Heartfulness and by imparting yogic Transmission. To be a trainer is not a profession. Trainers are volunteers who earn their livelihood through other means; rather, to be a trainer is a vocation. They are enabled to impart Transmission…...`,
      readmore:
        "https://heartfulness.org/en/faq/who-are-the-heartfulness-trainers-and-what-enables-them-to-impart-transmission/",
    },
    {
      id: 2,
      heading: `Is it necessary to learn Heartfulness from a trainer? Can I not learn it on my own?`,
      body: `The main purpose of learning from a trainer is for them to impart yogic Transmission. As already proposed, you can determine for yourself whether a trainer is necessary or not by conducting a simple experiment. Simply meditate on your own without a trainer, and when you are satisfied that you have fully comprehended that experience, meditate along with a trainer,…... `,
      readmore:
        "https://heartfulness.org/en/faq/dissolve-anger/",
    },
    {
      id: 3,
      heading: `How can we assess a gurus capabilities?`,
      body: `Just as you judge the capabilities of a chef by the quality of their food, you can judge the guru by the effect of his method and Transmission. To judge the method, you will have to practice it for some time. If you find its effects to be positive, then you can conclude something about the guru. If you wish,…... `,
      readmore:
        "https://heartfulness.org/en/faq/prevent-anger/",
    },
    {
      id: 4,
      heading: `What is the role of the guide?`,
      body: `At first, our relationship is with the practice, and there is no guide or guru involved. If we come to a point where our heart longs to know who is behind the effectiveness of the practice, we will observe that .the guru has been at work all along, silently and anonymously. We will observe that he works in the background,…... `,
      readmore: "https://heartfulness.org/en/faq/how-can-i-handle-the-thoughts-that-occur-during-meditation/",
    },
    {
      id: 5,
      heading: `Who is this guide or teacher at the center of the Heartfulness practice?`,
      body: `The current Heartfulness guide is Kamlesh Patel, known to many as Daaji. Born in Gujarat, India, in 1956, his own journey with Heartfulness started in 1976, while he was still a pharmacy student in Ahmedabad. After migrating to the USA, he raised a family and started a successful pharmacy business in New York City. In 2011, he was announced as…...`,
  
      readmore:
        "https://heartfulness.org/en/faq/who-is-this-guide-or-teacher-at-the-center-of-the-heartfulness-practice/",
    },
     
   
  ];
  