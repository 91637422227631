export const meditationfaqList = [
    {
      id: 1,
      heading: `Is meditation alone sufficient for self-improvement?`,
      body: `Meditation is a tool. The intention we make during meditation of “the Source of divine Light is already present within my heart, and is attracting me from within,” and the lifestyle we adopt in day-to-day life, will either be in sync or at odds. A conscious lifestyle and positive regenerative values will complement a meditative practice. When we adopt a…..`,
      readmore:
        "https://heartfulness.org/en/faq/is-meditation-alone-sufficient-for-self-improvement/",
    },
    {
      id: 2,
      heading: `Is it best to meditate at any specific time of day?`,
      body: `Meditating with regularity, at the same time ever day, makes meditation easy. It becomes our natural inclination at that moment – just as taking breakfast at the same time daily makes us naturally hungry at that time. With regularity of timing, we can sink into deep meditation from the very moment that we sit down. Regularity creates a biorhythm, establishing…... `,
      readmore:
        "https://heartfulness.org/en/faq/is-it-best-to-meditate-at-any-specific-time-of-day/",
    },
    {
      id: 3,
      heading: `When I meditate, I often feel like I fall asleep. What should I do?`,
      body: `Is it so easy to fall asleep in an upright pose in a matter of only a few minutes? We must understand that in deep meditation our condition resembles that of a sleeping person, and it is known as a Samadhi-like state. However, we can easily understand whether we have been sleeping or meditating by observing how we feel afterward.…...`,
      readmore:
        "https://heartfulness.org/en/faq/when-i-meditate-i-often-feel-like-i-fall-asleep-what-should-i-do/",
    },
    {
      id: 4,
      heading: `How can I handle the thoughts that occur during meditation?`,
      body: `The mind is our friend, not our enemy. Just as the eyes are for seeing and the ears are for hearing, the mind is for thinking. We cannot forcefully stifle its activity, nor should we. There is a reason for the rush of thoughts that sometimes occurs during meditation. Think of what happens when you open a fizzy beverage. There…...`,
      readmore: "https://heartfulness.org/en/faq/how-can-i-handle-the-thoughts-that-occur-during-meditation/",
    },
    {
      id: 5,
      heading: `For how long should we sit in meditation?`,
      body: `The length of meditation is dictated by how you feel. If you are benefiting from meditation you will sit longer. If you are disturbed, however, it is a good idea to start with the Heartfulness Cleaning for 10 minutes, and then attempt meditation. If you are able to sink into deep meditation within a few minutes, then perhaps you only….. `,
  
      readmore:
        "https://heartfulness.org/en/faq/for-how-long-should-we-sit-in-meditation/",
    },
    {
      id: 6,
      heading: `What is Meditation? What is the Source of divine Light upon which we meditate?`,
      body: `The essential principle of meditation is that you become what you meditate upon. You imbibe the qualities of that object of meditation. So what sort of object will lead us towards enlightenment? The object of Heartfulness Meditation is union with the higher Self, the Source, and that is also the meaning and purpose of Yoga. Only like can unite with…...`,
  
      readmore:
        "https://heartfulness.org/en/faq/must-we-be-seekers-of-spirituality-in-order-to-practice-heartfulness-is-it-acceptable-to-only-want-peace-and-relaxation/",
    },
    {
      id: 7,
      heading: `Is it best to meditate in a specific posture?`,
      body: `The best pose is the one in which you are comfortable. In Patanjali’s Yoga Sutras, he writes, “Posture should be that which is steady and comfortable.” Your pose should be steady enough that you can hold it easily for the duration of your meditation. However, what Patanjali points out is that only a comfortable pose can truly be steady, otherwise…...`,
  
      readmore:
        "https://heartfulness.org/en/faq/can-a-person-from-any-tradition-practice-heartfulness/",
    },
    {
      id: 8,
      heading: `Can children meditate?`,
      body: `Children from the age of 5 years can learn both the Heartfulness Relaxation and Heartfulness Prayer. Many children enjoy doing these practices, but there is also no need to force them. Anyone who has completed 15 years of age can learn all the Heartfulness practices, including Relaxation, Meditation, Cleaning and Prayer....`,
  
      readmore:
        "https://heartfulness.org/en/faq/how-long-do-i-need-to-practice-heartfulness-to-feel-the-effects-what-are-the-possible-effects-and-how-can-i-judge-the-efficacy-of-the-practices/",
    },
    {
      id: 9,
      heading: `What is yogic Transmission?`,
      body: `The most fascinating aspect of Heartfulness is pranahuti, or yogic Transmission. Transmission has been defined as “the utilization of divine energy for the transformation of a human being.” Here we are not speaking of physical transformation, as our bodies are limited by our genetic makeup. When we speak of transformation, we refer to our personality or our mental intellectual and…...`,
  
      readmore:
        "https://heartfulness.org/en/faq/how-long-do-i-need-to-practice-heartfulness-to-feel-the-effects-what-are-the-possible-effects-and-how-can-i-judge-the-efficacy-of-the-practices/",
    },
   
  ];
  