export const heartfulnessfaqList = [
  {
    id: 1,
    heading: `What is the role of science in Heartfulness?`,
    body: `Scientists have been studying meditation empirically for the last 60 years, and the impact of meditation on neuroplasticity, brain health and overall well-being has been established. We have sufficient proof that meditation improves stress management, sleep management, decisionmaking, emotional intelligence and relationship-building. Meditation has been shown to improve individual well-being, and this in turn positively impacts communities and society in…... `,
    readmore:
      "https://heartfulness.org/en/faq/what-is-the-role-of-science-in-heartfulness/",
  },
  {
    id: 2,
    heading: `What makes Heartfulness unique amongst so many traditions and paths?`,
    body: `Comparison is not the only way to assess value. For example, before you can enjoy a mango, do you first sample five or six other pieces of fruit to conclude that the mango is good? No, you simply enjoy the mango. You are happy to eat it. Similarly, when your heart is convinced by your experience with a particular practice,…...`,
    readmore:
      "https://heartfulness.org/en/faq/what-makes-heartfulness-unique-amongst-so-many-traditions-and-paths/",
  },
  {
    id: 3,
    heading: `What can we achieve by practicing Heartfulness?`,
    body: `The path of Heartfulness has stages and destinations, but let’s look at this from another perspective: are they things to be “achieved”? For example, suppose that a son stands to inherit the fortune of his mother. Should the idea of receiving the inheritance ever become his goal? Should that be his motivation for maintaining a good relationship with his mother?…... `,
    readmore:
      "https://heartfulness.org/en/faq/what-can-we-achieve-by-practicing-heartfulness/",
  },
  {
    id: 4,
    heading: `What is Heartfulness?`,
    body: `Heartfulness is a heart-centered approach to life, where you will ideally be able to live each moment by the heart. It is to live naturally, in tune with the noble qualities of a heart, enlightened and refined through spiritual practice. These qualities include compassion, sincerity, contentment, truthfulness, and forgiveness; attitudes such as generosity and acceptance; and the heart’s fundamental nature,…...`,
    readmore: "https://heartfulness.org/en/faq/what-is-heartfulness/",
  },
  {
    id: 5,
    heading: `Are Heartfulness and Sahaj Marg the same?`,
    body: `We often to use the terms “Heartfulness” and “Sahaj Marg” interchangeably. To be precise, Sahaj Marg is the name of the practices that were first offered to the world by Ram Chandra in the mid 20th century. It means “natural path.” Heartfulness is the heartful approach to this practice, to oneself, to others, and to life itself. Simply put, Heartfulness…...  `,

    readmore:
      "https://heartfulness.org/en/faq/you-mentioned-both-heartfulness-and-sahaj-marg-are-they-the-same-thing/",
  },
  {
    id: 6,
    heading: `Must we be seekers of spirituality in order to practice Heartfulness? Is it acceptable to only want peace and relaxation?`,
    body: `Purpose can never be imposed from the outside. Rather, it comes from within. In anything that you choose to do in life, your heart must be convinced. If you play chess, your heart must be convinced before you make a move; if you play music, your heart has to be convinced in order to play the melody. Follow your heart’s….. `,

    readmore:
      "https://heartfulness.org/en/faq/must-we-be-seekers-of-spirituality-in-order-to-practice-heartfulness-is-it-acceptable-to-only-want-peace-and-relaxation/",
  },
  {
    id: 7,
    heading: `Can a person from any tradition practice Heartfulness?`,
    body: `Yes. Heartfulness is based on direct perception and science rather than on religious beliefs, although the founders of all the great religions also based their teachings on their own direct perception and experience. Hence anyone from any traditional or religious background can benefit from these practices. Until you experience something for yourself, you will tend to doubt it. The practices…...  `,

    readmore:
      "https://heartfulness.org/en/faq/can-a-person-from-any-tradition-practice-heartfulness/",
  },
  {
    id: 8,
    heading: `How long do I need to practice Heartfulness to feel the effects? What are the possible effects and how can I judge the efficacy of the practices?`,
    body: `The fastest response can be immediate. You do not have to wait five years or ten years or even until the next day. The effect can be felt instantaneously, right then and there. It is like sunlight; when you expose yourself to the sun, you immediately feel warm. When exposed to yogic Transmission, you may feel its effects instantly. But…...`,

    readmore:
      "https://heartfulness.org/en/faq/how-long-do-i-need-to-practice-heartfulness-to-feel-the-effects-what-are-the-possible-effects-and-how-can-i-judge-the-efficacy-of-the-practices/",
  },
  {
    id: 9,
    heading: `Are there any fees or donations required to learn or practice Heartfulness?`,
    body: `No. Heartfulness practices are always free of charge. As Babuji used to say, “God is not for sale. And if God were for sale, how much would you pay? And if you could afford to buy God, then why would you need God?” The Heartfulness Institute and affiliated organizations run mainly on donations and grants, and are registered as charitable…..`,

    readmore:
      "https://heartfulness.org/en/faq/how-long-do-i-need-to-practice-heartfulness-to-feel-the-effects-what-are-the-possible-effects-and-how-can-i-judge-the-efficacy-of-the-practices/",
  },
  {
    id: 10,
    heading: `What is the origin of Heartfulness? Is it affiliated with any religious or spiritual tradition?`,
    body: `Behind every religious and spiritual tradition, there is a nameless, formless Reality. In Heartfulness Meditation, we approach that Reality directly. Therefore, this system is universal in its application, transcending name and form. It can resonate with any path that leads toward the Ultimate. The approach followed in Heartfulness evolved out of the ancient tradition of Raja Yoga. To learn more…..`,

    readmore:
      "https://heartfulness.org/en/faq/what-is-the-origin-of-heartfulness-is-it-affiliated-with-any-religious-or-spiritual-tradition/",
  },
  {
    id: 11,
    heading: `What qualifications or requirements are necessary to start the practices of Heartfulness?`,
    body: `There is only one requirement, and that is your interest. If you do it because someone else wants you to, it will not be as successful. This is true of any endeavor. Interest and willingness are key to success in anything. There is a saying that you can lead a horse to water, but you cannot make it drink...`,

    readmore:
      "https://heartfulness.org/en/faq/what-is-the-origin-of-heartfulness-is-it-affiliated-with-any-religious-or-spiritual-tradition/",
  },
];
