export const lifestylefaqList = [
    {
      id: 1,
      heading: `Will Heartfulness Meditation help me to sleep better?`,
      body: `Problems with sleep are endemic in modern urban society. Disciplining our sleep cycle changes our life, because how well and how deeply we sleep determines our state of mind throughout the day. Generally, it is better to sleep early to be in tune with natural cycles. Also, meditation will improve drastically, as we are not struggling with our consciousness. When…...`,
      readmore:
        "https://heartfulness.org/en/faq/will-heartfulness-meditation-help-me-to-sleep-better/",
    },
    {
      id: 2,
      heading: `With today’s hectic lifestyle, how can I find time to meditate?`,
      body: `If you can lead a stressful lifestyle, you also have the choice to create a calmer lifestyle. If you want to feel peaceful and calm, meditation is key. Even if it takes an hour of meditation to achieve twenty-three hours of a good day, is it not a good investment of time? Take the example of physical well-being: If you…... `,
      readmore:
        "https://heartfulness.org/en/faq/is-it-best-to-meditate-at-any-specific-time-of-day/",
    },
    {
      id: 3,
      heading: `Is Heartfulness compatible with normal family life and working life?`,
      body: `Most Heartfulness practitioners lead normal family lives, raising children and going to work. They are not extreme, in the sense that they don’t go into the material world so much that their spiritual world suffers, and they don’t go into the spiritual world so much that their material existence suffers. Heartfulness advocates a balanced existence: balance within, balance in the…... `,
      readmore:
        "https://heartfulness.org/en/faq/when-i-meditate-i-often-feel-like-i-fall-asleep-what-should-i-do/",
    },
    {
      id: 4,
      heading: `Does practicing Heartfulness require me to change me to change my lifestyle?`,
      body: `The name &#39;Sahaj Marg&#39;, or &#39;natural path&#39;, says it all. Everything about our approach to spirituality must be natural. Many people find that their lifestyle automatically adjusts itself after some time due to the inner changes brought about by the practice. Many unwanted habits simply drop off. Whenever you realize that you have a bad habit, however, and it is…..`,
      readmore: "https://heartfulness.org/en/faq/how-can-i-handle-the-thoughts-that-occur-during-meditation/",
    },
    {
      id: 5,
      heading: `Is there a particular diet that is more conducive for meditation?`,
      body: `A vegetarian diet is best suited for a wayfarer on the spiritual path. If you are attached to laddus or other sweets, and feel that the meal is not complete without them, even though this is vegetarian it will create unpleasant ripples within. The partaking of a meal with gratitude somehow evokes pious feelings and aids digestion and assimilation. If….`,
  
      readmore:
        "https://heartfulness.org/en/faq/is-there-a-particular-diet-that-is-more-conducive-for-meditation/",
    },
    {
      id: 6,
      heading: `Should I meditate during sickness?`,
      body: `If you can sit comfortably without being disturbed, feel free to meditate. The moment you begin to feel uneasy, there is no need to force yourself to meditate.... `,
  
      readmore:
        "https://heartfulness.org/en/faq/must-we-be-seekers-of-spirituality-in-order-to-practice-heartfulness-is-it-acceptable-to-only-want-peace-and-relaxation/",
    },
    {
      id: 7,
      heading: `How can I meditate if I am travelling?`,
      body: `Do you stop taking breakfast or lunch while traveling? When you are in a flight or traveling by train, if the heart is inclined to meditate there is no harm. Simply be careful of your individual possessions if you are alone, and ensure that the destination is not missed!...`,
  
      readmore:
        "https://heartfulness.org/en/faq/can-a-person-from-any-tradition-practice-heartfulness/",
    },
    {
      id: 8,
      heading: `How can we balance the material and spiritual aspects of lives?`,
      body: `Heartfulness does not distinguish between the material and spiritual aspects of life. A bird flies with both wings. When both wings are equally outstretched, the bird flies as straight as an arrow. If the bird leans towards one side, it flies in circles. Our life also has two wings. We can call them the material and spiritual wings. If we…...`,
  
      readmore:
        "https://heartfulness.org/en/faq/how-long-do-i-need-to-practice-heartfulness-to-feel-the-effects-what-are-the-possible-effects-and-how-can-i-judge-the-efficacy-of-the-practices/",
    },
    
   
  ];
  