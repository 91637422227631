export const angerfaqList = [
    {
      id: 1,
      heading: `Let Go Removal of Fear`,
      body: `Problems with sleep are endemic in modern urban society. Disciplining our sleep cycle changes our life, because how well and how deeply we sleep determines our state of mind throughout the day. Generally, it is better to sleep early to be in tune with natural cycles. Also, meditation will improve drastically, as we are not struggling with our consciousness. When…...`,
      readmore:
        "https://heartfulness.org/en/faq/will-heartfulness-meditation-help-me-to-sleep-better/",
    },
    {
      id: 2,
      heading: `Dissolve Anger`,
      body: `Anger is an emotional reaction that is often expressed outwardly against someone or something. The reaction leaves an impression in your field of consciousness and deprives it of its original joyful state. Cleaning of these impressions restores that joyful state. Whenever you experience anger, take a few minutes to clean and free yourself from the effects. Guided “Ocean of Peace”…...`,
      readmore:
        "https://heartfulness.org/en/faq/dissolve-anger/",
    },
    {
      id: 3,
      heading: `Prevent Anger`,
      body: `Anger expresses in the way we speak and behave, leading to harsh words, arguments, and hurtful actions. By connecting to your higher Self and modulating your speech to resonate with the gentle current of the Divine, you can learn to manage anger by preventing its negative manifestation. Over time, by practicing the following exercise, you can manage the way you…...`,
      readmore:
        "https://heartfulness.org/en/faq/prevent-anger/",
    },
    {
      id: 4,
      heading: `Why Detox Anger?`,
      body: `If misused, the fire of anger can destroy a happy family, friendship and peace of mind. It can lead to bitterness and revenge, self-affliction, loss of self-confidence and loss of courage.  Anger affects your body, mind, and heart. It is a contraction of energy manifesting as stiffness in the body, physical dysfunction, and a loss of energy, sensitivity, and awareness.…...`,
      readmore: "https://heartfulness.org/en/faq/how-can-i-handle-the-thoughts-that-occur-during-meditation/",
    },
    {
      id: 5,
      heading: `Restore Positivity`,
      body: `Anger leaves an impression on your heart. Though the anger may have passed, the emotional memory may still remain and become the seed for a future bout of anger. This can become a habit. Flooding the heart with higher love can dissolve the habit and offer permanent freedom. GUIDED MEDITATION • Gently close your eyes and let your body relax.…...`,
  
      readmore:
        "https://heartfulness.org/en/faq/restore-positivity/",
    },
    {
      id: 6,
      heading: `Remove the Physical Effects`,
      body: `Anger also affects the physical body—so releasing physical tension eases the inner body, emotions and thought flow. GUIDED RELAXATION AND ATTENTION • Sit comfortably and gently close your eyes. • Relax your hands and fingers. Feel the tension-reducing. • Let your shoulders drop. • Bring your awareness to your face, drop your jaw, and relax your mouth. • Feel all…...`,  
      readmore:
        "https://heartfulness.org/en/faq/must-we-be-seekers-of-spirituality-in-order-to-practice-heartfulness-is-it-acceptable-to-only-want-peace-and-relaxation/",
    },
   
   
  ];
  