export const prayerfaqList = [
    {
      id: 1,
      heading: `In the Heartfulness prayer, to what does the term Master refer?`,
      body: `The term ‘Master’ refers to God – the Divinity within each of our hearts. But if the prayer is addressed to God, then why repeat the sentence, “Thou art the only God and Power to bring us up to that stage?” This question is worth pondering, and you will have to draw your own conclusion. In fact, the entire Heartfulness…... `,
      readmore:
        "https://heartfulness.org/en/faq/in-the-heartfulness-prayer-to-what-does-the-term-master-refer/",
    },
    {
      id: 2,
      heading: `What is the purpose of prayer?`,
      body: `Prayer has been the natural response of humans to cope with difficult situations in life, such as poverty, petulance, sickness, death or disaster. Traditionally we pray to a higher power or god for things of necessity and want. Prayer cultivates an attitude of humility and acceptance, and establishes our relationship with the source in the right manner. It creates a…...`,
      readmore:
        "https://heartfulness.org/en/faq/what-is-the-purpose-of-prayer/",
    },
    {
      id: 3,
      heading: `When should we offer the Heartfulness Prayer?`,
      body: `Begin your morning meditation with a silent offering of the Heartfulness Prayer. Offer it once and start meditation. You may also offer the same prayer at night, just before going to sleep. At that time, repeat the prayer a few times, and allow yourself to become absorbed in the feelings that unfold within you. In this prayerful state, go to…..`,
      readmore:
        "https://heartfulness.org/en/faq/prevent-anger/",
    },
    
   
  ];
  