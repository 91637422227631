export const cleaningfaqList = [
    {
      id: 1,
      heading: `Is it possible to clean away certain ailments?`,
      body: `It is recommended that you try the Heartfulness Relaxation. At the end of the process, revisit the affected organ and allow the energy from Mother Earth to flow there. Spend more time with that organ, and if necessary you can gently touch the same with your hands and allow the energy to comfort the organ or the region. Also, during…..`,
      readmore:
        "https://heartfulness.org/en/faq/is-it-possible-to-clean-away-certain-ailments/",
    },
    {
      id: 2,
      heading: `Suppose I miss the cleaning routine in the evening, what should I do?`,
      body: `If you miss the chance to complete the cleaning in the evening, you can attempt it just before you go to bed....`,
      readmore:
        "https://heartfulness.org/en/faq/dissolve-anger/",
    },
    {
      id: 3,
      heading: `When should I do the Cleaning?`,
      body: `Because cleaning removes the accumulated impressions of the day, it is best done in the evening, after you have finished the majority of the day’s work. It is like coming home and immediately taking a shower before doing anything else. Because cleaning requires alertness, it is best not to put it off until bedtime, when you are too sleepy to…... `,
      readmore:
        "https://heartfulness.org/en/faq/prevent-anger/",
    },
    {
      id: 4,
      heading: `What is being cleaned in the Heartfulness Cleaning practice?`,
      body: `Cleaning is a way to remove the emotional burdens that we carry on a daily basis. These burdens are the emotional content of our experiences, and the emotional residue that lingers from our past, our memories. In yogic terminology, these burdens are known as samskaras, or impressions. Over time, impressions harden into tendencies of thought and emotion, causing us to…... `,
      readmore: "https://heartfulness.org/en/faq/how-can-i-handle-the-thoughts-that-occur-during-meditation/",
    },
    {
      id: 5,
      heading: `Should I recall all the happenings of the day and then throw them out during the cleaning process?`,
      body: `Recalling daily events will only deepen their impact. The idea is to remove the impact created by events without recalling them...`,
  
      readmore:
        "https://heartfulness.org/en/faq/breathing-exercise/",
    },
     
   
  ];
  