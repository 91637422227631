import React, { useState, useEffect } from "react"

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import { FaHeart } from 'react-icons/fa';

import { FaGlobeEurope } from 'react-icons/fa';

import { FaSmile } from 'react-icons/fa';

import { FaBackspace } from 'react-icons/fa';

import { FaQuestion } from 'react-icons/fa';

import { FaRecycle } from 'react-icons/fa';

import { FaHandshake } from 'react-icons/fa';

import { FaExchangeAlt } from 'react-icons/fa';

import { FaSun } from 'react-icons/fa';

// import { faqList } from "../../assets/data/faq";

import { heartfulnessfaqList } from "../../assets/data/heartfulness-faq";

import { meditationfaqList } from "../../assets/data/meditation-faq";

import { lifestylefaqList } from "../../assets/data/lifestyle-faq";

import { angerfaqList } from "../../assets/data/anger-faq";

import { fearfaqList } from "../../assets/data/fear-faq";

import { cleaningfaqList } from "../../assets/data/cleaning-faq";

import { instructorfaqList } from "../../assets/data/instructor-faq";

import { stressfaqList } from "../../assets/data/stress-faq";

import { prayerfaqList } from "../../assets/data/prayer-faq";

import { Accordion } from "react-bootstrap";

// import ceoclub from "../../assets/images/retreats/ceo-clubs.png";

export default function Faqsliderpage() {

  const [value, setValue] = useState('Heartfulness');

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 6,
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group" style={{ margin: "-123px 0px 0px 0px"}}>
        <div className="navigations">
          <button className="btn-faq" style={{backgroundColor:"white"}} onClick={() => previous()}>
            {/* <img src={treeLeftIconGreen} alt='treeLeftIconGreen' /> */}

          </button>
          <button className="btn-faq-next" style={{backgroundColor:"white"}} onClick={() => next()}>
            {/* <img src={treeRightIconGreen} alt='treeRightIconGreen' /> */}
          </button>
        </div>
      </div>
    );
  };
  const handleClick = (event) => {
    let value = event.target.id;
    setValue(value);
  }

  return (
    <section className="faqslider-page-wrapper pad60">
      <div className="container">
        {/* <div className="row">
          <div className="col-md-10 cent-item">
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="Ask a Question"
                name="question"
              />
              <p className="theme-p-tag">
                {" "}
                Or choose a category to quickly find the help you need
              </p>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12">
            <div className="testimonial-slider container pad60">
              <div className="row">
                <div className="carousel-section-faq">
                  <Carousel
                    arrows={false}
                    showDots={true}
                    autoPlay={false}
                    draggable
                    infinite={true}
                    keyBoardControl
                    
                    transitionDuration={1000}
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={true}
                    swipeable
                    responsive={responsive}
                    customButtonGroup={<ButtonGroup />}

                  >


                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">
                        <div className="testimonial-card1">
                          <div className="testimonial-card-body1 shadow-none" >
                            <div className="faq-main-page-section-slide" id="Heartfulness">
                              <span className="faq-icon-wid" id="Heartfulness">
                                <FaHeart size={36} />
                              </span>
                              <p id="Heartfulness"> Heartfulness</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">
                        <div className="testimonial-card1">
                          <div className="testimonial-card-body1 shadow-none">
                            <div className="faq-main-page-section-slide" id="Meditation">
                              <span className="faq-icon-wid" id="Meditation">
                                <FaGlobeEurope size={36} />
                              </span>
                              <p id="Meditation"> Meditation </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">
                        <div className="testimonial-card1">
                          <div className="testimonial-card-body1 shadow-none">
                            <div className="faq-main-page-section-slide" id="LifeStyle">
                              <span className="faq-icon-wid" id="LifeStyle">
                                <FaSmile size={36} />
                              </span>
                              <p id="LifeStyle"> LifeStyle </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">
                        <div className="testimonial-card1">
                          <div className="testimonial-card-body1 shadow-none">
                            <div className="faq-main-page-section-slide" id="Anger">
                              <span className="faq-icon-wid" id="Anger">
                                <FaBackspace size={36} />
                              </span>
                              <p id="Anger">Anger Detox </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">
                        <div className="testimonial-card1" >
                          <div className="testimonial-card-body1 shadow-none">
                            <div className="faq-main-page-section-slide" id="Fear">
                              <span className="faq-icon-wid" id="Fear">
                                <FaQuestion size={36} />
                              </span>
                              <p id="Fear"> Fear Detox </p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">

                        <div className="testimonial-card1"  >
                          <div className="testimonial-card-body1 shadow-none">
                            <div className="faq-main-page-section-slide" id="Cleaning">
                              <span className="faq-icon-wid" id="Cleaning">
                                <FaRecycle size={36} />
                              </span>
                              <p id="Cleaning"> Cleaning </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">
                        <div className="testimonial-card1">
                          <div className="testimonial-card-body1 shadow-none">
                            <div className="faq-main-page-section-slide" id="Instructors">
                              <span className="faq-icon-wid" id="Instructors">
                                <FaHandshake size={36} />
                              </span>
                              <p id="Instructors"> Instructors </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">
                        <div className="testimonial-card1">
                          <div className="testimonial-card-body1 shadow-none">
                            <div className="faq-main-page-section-slide" id="Stress">
                              <span className="faq-icon-wid" id="Stress">
                                <FaExchangeAlt size={36} />
                              </span>
                              <p id="Stress"> Stress Detox </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div onClick={handleClick} onKeyDown={handleClick} role="presentation">
                      <div className="col-md-12 mar0">
                        <div className="testimonial-card1">
                          <div className="testimonial-card-body1 shadow-none">
                            <div className="faq-main-page-section-slide" id="Prayer">
                              <span className="faq-icon-wid">
                                <FaSun size={36} />
                              </span>
                              <p id="Prayer" > Prayer </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pad59">
          <div className="col-md-10 cent-item">
            {value === "Heartfulness" && <div>
              <div class="term-heading">
                <h3 class="heading-wrapper theme-color">Heartfulness </h3>
              </div>
              <Accordion className="faq-accordion-wrapper">
                {heartfulnessfaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion></div>}
            {value === "Meditation" && <div>
              <div class="term-heading"><h3 class="heading-wrapper theme-color"> Meditation </h3></div>
              <Accordion className="faq-accordion-wrapper">
                {meditationfaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>}
            {value === "LifeStyle" && <div> <h3 class="heading-wrapper theme-color"> LifeStyle </h3>

              <Accordion className="faq-accordion-wrapper">
                {lifestylefaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>

            </div>}
            {value === "Anger" && <div> <h3 class="heading-wrapper theme-color"> Anger Detox </h3>

              <Accordion className="faq-accordion-wrapper">
                {angerfaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>

            </div>}
            {value === "Fear" && <div> <h3 class="heading-wrapper theme-color">  Fear Detox  </h3>

              <Accordion className="faq-accordion-wrapper">
                {fearfaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>

            </div>}
            {value === "Cleaning" && <div> <h3 class="heading-wrapper theme-color"> Cleaning </h3>

              <Accordion className="faq-accordion-wrapper">
                {cleaningfaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>

            </div>}
            {value === "Instructors" && <div> <h3 class="heading-wrapper theme-color"> Instructors </h3>

              <Accordion className="faq-accordion-wrapper">
                {instructorfaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>

            </div>}
            {value === "Stress" && <div> <h3 class="heading-wrapper theme-color"> Stress Detox </h3>

              <Accordion className="faq-accordion-wrapper">
                {stressfaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>

            </div>}
            {value === "Prayer" && <div> <h3 class="heading-wrapper theme-color"> Prayer </h3>

              <Accordion className="faq-accordion-wrapper">
                {prayerfaqList.map((item) => {
                  return (
                    <Accordion.Item className="accordian-item-color" eventKey={item.id} key={item.id}>
                      <Accordion.Header className="accordion-header">
                        {item.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                        <span>
                          {" "}
                          <a
                            href={item.readmore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                          </a>{" "}
                        </span>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>

            </div>}

          </div>
        </div>
      </div>
    </section>
  );
}
