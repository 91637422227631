export const stressfaqList = [
    {
      id: 1,
      heading: `Detox & Rejuvenate Your Mind`,
      body: `Cleaning the mind of its excessive thoughts and worries restores and rejuvenates the mind. GUIDED LIMB CLEANING • Imagine the divine current is flowing from above you. • Draw the current down to your heart. • Now let this current move towards your left shoulder, then let it descend down your arm to your biceps, your elbow, your wrist, your…...`,
      readmore:
        "https://heartfulness.org/en/faq/who-are-the-heartfulness-trainers-and-what-enables-them-to-impart-transmission/",
    },
    {
      id: 2,
      heading: `Relieve Stress`,
      body: `Fast breathing is one manifestation of stress. Conscious efforts to slow down your breathing can reduce the effects of stress and bring immediate relief. LEFT NOSTRIL BREATHING • Relax your body and mind. • Close your right nostril with your right thumb. • Smile while you breathe. • Slowly inhale through your left nostril and exhale through your left nostril,…...`,
      readmore:
        "https://heartfulness.org/en/faq/dissolve-anger/",
    },
    {
      id: 3,
      heading: `Why Detox Stress?`,
      body: `Life is a continuous process of facing and solving problems and challenges, as we are living in the VUCA world, and sometimes it is overwhelming. Our bodies and minds get stressed. Not all stress is bad – eustress is positive stress, and distress is harmful stress – but prolonged, uncontrollable distress can negatively affect our immunological, neurological, physiological, and psychological…... `,
      readmore:
        "https://heartfulness.org/en/faq/prevent-anger/",
    },
    {
      id: 4,
      heading: `Relax Your Body and Mind`,
      body: `Stress originates in the energy field of the heart-mind and then affects different parts of the body. Relaxation can have deeper effects on both the body and mind. GUIDED RELAXATION • Sit comfortably and close your eyes very softly and very gently. • Let’s begin with the toes. Wiggle your toes. Now feel them relax. • Feel the healing energy…...`,
      readmore: "https://heartfulness.org/en/faq/how-can-i-handle-the-thoughts-that-occur-during-meditation/",
    },
    {
      id: 5,
      heading: `Restore Positivity`,
      body: `Anger leaves an impression on your heart. Though the anger may have passed, the emotional memory may still remain and become the seed for a future bout of anger. This can become a habit. Flooding the heart with higher love can dissolve the habit and offer permanent freedom. GUIDED MEDITATION • Gently close your eyes and let your body relax.…... `,
  
      readmore:
        "https://heartfulness.org/en/faq/who-is-this-guide-or-teacher-at-the-center-of-the-heartfulness-practice/",
    },
     
   
  ];
  